import base64url from 'base64url';
import { useMutation } from 'react-query';
import request, { RequestError, RequestResponse } from 'request';
import { D2P_AUTH_HEADER } from 'src/config/constants';

export type RegisterRequest = {
  authToken: string;
};

export type RegisterResponse = {
  data: string;
};

type ChallengeJson = {
  userVaultId: string;
  credentialId: string;
  publicKey: PublicKeyCredentialCreationOptions;
  attestationData: string[];
};

const generateDeviceResponse = async (challenge: string) => {
  const challengeJson = JSON.parse(challenge) as ChallengeJson;
  const { publicKey } = challengeJson;
  publicKey.challenge = base64url.toBuffer(
    publicKey.challenge as unknown as string,
  );
  publicKey.user.id = base64url.toBuffer(
    publicKey.user.id as unknown as string,
  );
  const publicKeyCredential = (await window.navigator.credentials.create({
    publicKey,
  })) as PublicKeyCredential;
  const attestationObject = base64url.encode(
    (publicKeyCredential.response as any).attestationObject as Buffer,
  );
  const clientDataJSON = base64url.encode(
    publicKeyCredential.response.clientDataJSON as Buffer,
  );
  const pk = {
    rawId: base64url.encode(publicKeyCredential.rawId as Buffer),
    id: publicKeyCredential.id,
    type: 'public-key',
    response: {
      clientDataJSON,
      attestationObject,
    },
  };
  return JSON.stringify(pk);
};

const register = async (payload: RegisterRequest) => {
  const { authToken } = payload;
  const { data: initResponse } = await request<
    RequestResponse<{
      challengeToken: string;
      challengeJson: string;
    }>
  >({
    method: 'POST',
    url: '/user/biometric/init',
    data: payload,
    headers: {
      [D2P_AUTH_HEADER]: authToken,
    },
  });

  const { challengeToken } = initResponse.data;
  const deviceResponseJson = await generateDeviceResponse(
    initResponse.data.challengeJson,
  );

  const { data: response } = await request<RequestResponse<RegisterResponse>>({
    method: 'POST',
    url: '/user/biometric',
    data: {
      deviceResponseJson,
      challengeToken,
    },
    headers: {
      [D2P_AUTH_HEADER]: authToken,
    },
  });
  return response.data;
};

const useRegister = () =>
  useMutation<RegisterResponse, RequestError, RegisterRequest>(register);

export default useRegister;
